import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { AppUtilService } from './shared/services/app-util.service';
import { LoginMessageService } from './shared/services/auth';
import { tap } from 'rxjs/operators/tap';
import { startWith, delay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from './shared/typings/common-constants';
import { KalturaAppService } from './shared/services/kaltura-app.service';
import { UserFormService } from './pages/default/user-management/services/user-form.service';
import { SnackbarUtilService } from './shared/services/snackbar-util.service';
import { OmniService } from './pages/default/Omni/omni.service';
declare var $: any;

@Component({
  selector: '.fullcontainer  .main-container',
  templateUrl: './theme.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {
  isBrowser;
  isMobileTabletView: boolean = false;
  isMobileView: boolean = false;
  ugcPageVisited: boolean = false;
  isShowFullRegisterGrievancePage: boolean = false;
  userDetailsSSO: any;
  validationToken: any;
  devicesLimit: any;
  householdId: any;
  eligibilityText: any;
  authToken: any;
  showCookie: boolean = false;
  loading: boolean;
  landingPage: boolean;
  unifiedPage: boolean;
  OTTPlans: boolean;
  platform: string;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public appUtilService: AppUtilService,
    private ugcOpenMessageService: LoginMessageService,
    private activatedRoute: ActivatedRoute,
    private kalturaAppService: KalturaAppService,
    private userFormService: UserFormService,
    private snackbarUtilService: SnackbarUtilService,
    private OMNIService: OmniService,
    private router: Router,

  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.ugcPageVisited = false;
    if (this.isBrowser) {
      this.getSSOLogin()
      if (location.pathname.split("/").length >= 2) {
        if (location.pathname.split("/")[1] === "mobile") {
          this.isShowFullRegisterGrievancePage = true;
        } else {
          this.isShowFullRegisterGrievancePage = false;
        }
      }
      if (matchMedia('(max-width: 992px)').matches) {
        this.isMobileView = false;
        if (matchMedia('(max-width: 768px)').matches) {
          this.isMobileView = true;
        }
        this.isMobileTabletView = true;
      } else {
        this.isMobileTabletView = false;
      }
      // detect mobile and render mobile side bar
      $(window).bind("orientationchange", () => {

        // switch(window.orientation) {  
        //   case -90 || 90 || 0:
        //     alert('landscape');
        //     this.isMobileTabletView = true;
        //     break;
        //   default:
        //     alert('portrait');
        //     this.isMobileTabletView = false;
        //     break; 
        // }
        if (!this.isMobileView) {
          setTimeout(() => {
            if (matchMedia('(max-width: 768px)').matches) {
              this.isMobileView = true;
              this.isMobileTabletView = true;
            } else {
              this.isMobileTabletView = !this.isMobileTabletView;
            }
          }, 400)
        }
      });
      // detect mobile and render mobile side bar


    }
  }

  ngAfterViewInit(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    this.ugcOpenMessageService.messageChanged$.pipe(startWith(null),
      delay(0), tap((ugcPageVisited) => {
        try {
          if (ugcPageVisited) {
            this.ugcPageVisited = true;
          } else {
            this.ugcPageVisited = false;
          }
        } catch (e) {
          this.ugcPageVisited = false;
        }
      })).subscribe()
  }

  SubscriptionPlanID: any;
  instantActivation: any;

  getSSOLogin() {
    this.activatedRoute.queryParams.subscribe(res => {
      if (res.authToken && res.mobileNumber) {
        if (res.deviceId) {
          localStorage.setItem('deviceId',res.deviceId)
        }
        if (res.SubscriptionPlanID) {
          this.loading = true;
          this.SubscriptionPlanID = res.SubscriptionPlanID
          this.authToken = res.authToken
          this.getUserDetailsSSO(res.mobileNumber, res.authToken)
          if (res.instant) {
            this.instantActivation = res.instant
          }
        }
        else if (res.redirectTO == 'OTTPlans') {
          this.loading = true;
          this.OTTPlans = true
          this.authToken = res.authToken
          this.getUserDetailsSSO(res.mobileNumber, res.authToken)
        }
        else if (res.redirectTO == 'Unified') {
          this.loading = true;
          this.unifiedPage = true
          this.authToken = res.authToken
          this.getUserDetailsSSO(res.mobileNumber, res.authToken)
        }
        else {
          this.loading = true;
          this.landingPage = true
          this.authToken = res.authToken
          this.getUserDetailsSSO(res.mobileNumber, res.authToken)
        }
      }
    });
  }

  getUserDetailsSSO(UserId, token) {
    let userId = UserId
    let UserType = 'mobile'
    this.userFormService.getUserDetailsSSO(userId, UserType, token).subscribe((res: any) => {
     
      if (res.body.ResultCode == 0) {
       
        this.userDetailsSSO = res.body
        // console.log("nandini",this.userDetailsSSO.Result.CustomerCategory)
        // console.log("sukriti",JSON.stringify(this.userDetailsSSO.Result.OTTSMSID
        // ))
      
        // this.validationToken = res.Result.DishToken
        this.saveDataIntoLocalStorage(this.userDetailsSSO)
        // this.SSOLogin()
      }
    })
  }

  async saveDataIntoLocalStorage(user) {
    localStorage.setItem(AppConstants.USER_CATEGORY, this.userDetailsSSO.Result.CustomerCategory);
    localStorage.setItem(AppConstants.USER_DETAILS_SMS, JSON.stringify(this.userDetailsSSO.Result));
    localStorage.removeItem(AppConstants.IS_DISH_USER);
    if (Number(this.userDetailsSSO.Result.CustomerCategory === 1) || Number(this.userDetailsSSO.Result.CustomerCategory === 2)) {
      localStorage.setItem(AppConstants.IS_DISH_USER, 'true');
    } else if (Number(this.userDetailsSSO.UserCategory === 3)) {
      localStorage.setItem(AppConstants.IS_DISH_USER, 'false');
    } else {
      localStorage.setItem(AppConstants.IS_DISH_USER, 'false');
    }
    if (this.isBrowser) {
      localStorage.removeItem(AppConstants.AUTH_HEADER_KEY)
      localStorage.setItem(AppConstants.AUTH_HEADER_KEY, this.authToken)
      localStorage.removeItem(AppConstants.USER_DETAILS)
      localStorage.setItem(AppConstants.USER_DETAILS, JSON.stringify(user))
    }
    this.platform = this.userDetailsSSO.Result.CustomerCategory == '1' ? 'mydishtvspace' : 'myd2hspace';
    this.router.navigate([`/user/${this.platform}`]);
  }

  SSOLogin() {
    localStorage.setItem(AppConstants.USER_CATEGORY, this.userDetailsSSO.UserCategory);
    localStorage.setItem(AppConstants.USER_DETAILS_SMS, JSON.stringify(this.userDetailsSSO));
    localStorage.removeItem(AppConstants.IS_DISH_USER);
    if (Number(this.userDetailsSSO.UserCategory === 1) || Number(this.userDetailsSSO.UserCategory === 2)) {
      localStorage.setItem(AppConstants.IS_DISH_USER, 'true');
    } else if (Number(this.userDetailsSSO.UserCategory === 3)) {
      localStorage.setItem(AppConstants.IS_DISH_USER, 'false');
    } else {
      localStorage.setItem(AppConstants.IS_DISH_USER, 'false');
    }
    this.kalturaAppService.userLogin(this.userDetailsSSO.OTTSubscriberID, this.validationToken).then(response => {
      if (response.loginSession.ks) {
        this.loading = false;
        // this.loginMessageService.sendLoginMessage(true)
        if (this.isBrowser) {
          localStorage.removeItem(AppConstants.AUTH_HEADER_KEY)
          localStorage.setItem(AppConstants.AUTH_HEADER_KEY, this.authToken)
          localStorage.setItem(AppConstants.KS_KEY, response.loginSession.ks)
          localStorage.removeItem(AppConstants.USER_DETAILS)
          localStorage.setItem(AppConstants.USER_DETAILS, JSON.stringify(response.user))
        }
        this.kalturaAppService.setKs()
        this.houseHold();
        this.extendToken();
        if (this.landingPage) {
          this.router.navigate(['/'])
        }
        else if (this.OTTPlans) {
          this.router.navigate(['/OTTPlans'])
        }
        else if (this.unifiedPage) {
          if (this.userDetailsSSO.UserCategory == 1) {
            this.router.navigate(['/user/mydishtvspace'])
          }
          else {
            this.router.navigate(['/user/myd2hspace'])
          }
        }
        else {
          if (this.instantActivation) {
            this.router.navigate(['/OTTPlans/Payment-Page'], {
              queryParams: {
                SubscriptionPlanID: this.SubscriptionPlanID,
                instant: this.instantActivation
              }
            });
          }
          else {
            this.router.navigate(['/OTTPlans/Payment-Page'], {
              queryParams: {
                SubscriptionPlanID: this.SubscriptionPlanID,
              }
            });
          }
        }
        this.loginCompleteMoEngageEventWithOTPForMobileNo();
      } else {
        this.snackbarUtilService.showError()
      }
    }, reject => {
      this.loading = false
      this.snackbarUtilService.showError()
    })
  }

  loginCompleteMoEngageEventWithOTPForMobileNo() {
    let loginComplete = {
      id_type: 'mobile_number_watcho',
      authentication_type: 'OTP',
      status: 'login_successful'
    }
    this.appUtilService.moEngageEventTracking('LOGIN_COMPLETE', loginComplete);
  }


  extendToken() {
    this.loading = true
    this.kalturaAppService.addToken().then(res => {
      this.loading = false
    }, reject => {
      this.loading = false
      this.snackbarUtilService.showError()
    })
  }

  houseHold() {
    this.loading = true
    this.kalturaAppService.getHousehold().then(res => {
      this.loading = false
      this.devicesLimit = res.devicesLimit
      this.householdId = res.id
      this.getHouseholdDeviceList()
    }, reject => {
      this.loading = false
      this.snackbarUtilService.showError()
    })
  }

  getHouseholdDeviceList() {
    this.loading = true
    this.kalturaAppService.getHouseholdList().then(res => {
      this.loading = false
      if (this.checkDeviceAlreadyAdded(res)) {
        this.reloadActivatedRoute();
      } else {
        this.addDeviceToHousehold();
      }
      // }

    }, reject => {
      this.loading = false
      this.snackbarUtilService.showError()
    })
  }

  checkDeviceAlreadyAdded(res): boolean {
    let isDeviceAdded = false
    if (this.isBrowser) {
      let udid = this.appUtilService.getDeviceId();
      if (res.objects) {
        res.objects.forEach((element, index) => {
          if (element.udid === udid) {
            isDeviceAdded = true
          }
        })
      }
      return isDeviceAdded
    }
  }

  addDeviceToHousehold() {
    this.loading = true
    this.kalturaAppService.addHouseholdDevice(this.appUtilService.getBrowserDetails()).then(response => {
      this.snackbarUtilService.showError('device added..');
      this.reloadActivatedRoute();
    }, reject => {
      this.loading = false
      this.snackbarUtilService.showError(reject.message);
    })
  }

  reloadActivatedRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/search', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl(currentUrl));
  }
}
