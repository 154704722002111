import { HttpClient, HttpHeaders, HttpResponse,HttpParams } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class MyDishTvSpaceService {
    ottApiUrl: string;
    epgApiUrl: string;
    isBrowser: any;
    private secretKey = 'AES';
    encOttUrl:any ="http://unifiedbck.watcho.com/";
    dishEncOttUrl: string = "";

    constructor(@Inject(PLATFORM_ID) private platformId, private httpClient: HttpClient) {
        this.ottApiUrl = environment.OTT_API_URL
        this.epgApiUrl = environment.EPG_API_URL
        this.isBrowser = isPlatformBrowser(platformId);
   
    }
    // getsubscriberDetail(token: string,inputparams: any): Observable<HttpResponse<any>>{
    //     let encurl = `${this.encOttUrl}api/UniFiedAPP/GetSubscriberDetails`;
    //     let headers = new HttpHeaders().set('Authorization', token);
    //     headers = headers.append('Content-Type', 'application/json');
    //     let body = inputparams
    //     return this.httpClient.post(encurl , body, {headers}).pipe((res: Observable<HttpResponse<any>>) => {
    //         return res;
    //     });
    // }

    getDishTvSpaceToken() {
        let epgApiUrl = `${this.epgApiUrl}/${this.getUserPlatform()}/api/v1/epg/`;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        let body = {
            clientid: "watcho-web-app",
            password: "K^o!62Jr1*feEKW6"
        };
        return this.httpClient.post(epgApiUrl + "client/auth/signin", body, { headers })
            .pipe(map(res => {
                this.setEpgToken(res);
                return res;
            }));
    }


    getChannelsList(token: string): Observable<HttpResponse<any>> {
        let epgApiUrl = `${this.epgApiUrl}/${this.getUserPlatform()}/api/v1/epg/`;
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        let body = {};
        body['sortby'] = 'lcn';
        return this.httpClient.post(epgApiUrl + "entities/channels", body, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    getGenresList(token: string): Observable<HttpResponse<any>> {
        let epgApiUrl = `${this.epgApiUrl}/${this.getUserPlatform()}/api/v1/epg/`;
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        let body = {};
        return this.httpClient.post(epgApiUrl + "entities/categories", body, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    getProgrameList(token: string, pageSize: number, pageIndex: number, reqObj: object): Observable<HttpResponse<any>> {
        let epgApiUrl = `${this.epgApiUrl}/${this.getUserPlatform()}/api/v1/epg/`;
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        let body = reqObj;
        return this.httpClient.post(epgApiUrl + "entities/channelswithprograms?page=" + pageIndex + "&limit=" + pageSize, body, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
}
    // getUserAccountDetails(token: string, OTTSubscriberID: string): Observable<HttpResponse<any>> {
    //     let headers = new HttpHeaders().set('Authorization', token);
    //     headers = headers.append('Content-Type', 'application/json');
    //     return this.httpClient.get(`${this.ottApiUrl}Recharge/GetSubscriberDetails/${OTTSubscriberID}`, { headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
    //         return res;
    //     });
    // }
    getUserAccountDetails(token: string, OTTSubscriberID: string): Observable<HttpResponse<any>> {
        let body: any = {
            OTTSMSID: OTTSubscriberID,
            Source: 'Web'
        }
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/GetSubscriberDetails`, body, { headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });
    }
    getUserAccountEncryptDetails(encrypt: string, OTTSubscriberID: string): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', encrypt);
        headers = headers.append('Content-Type', 'application/json');
        const bytes = CryptoJS.AES.decrypt(OTTSubscriberID, this.secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        console.log("****98**",decryptedData);
        return this.httpClient.get(`${this.ottApiUrl}Recharge/GetSubscriberDetails/${decryptedData}`, { headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });
    }

    // refreshAccount(token: string, Dishd2hSubscriptionID: number): Observable<HttpResponse<any>> {
    //     let headers = new HttpHeaders().set('Authorization', token);
    //     headers = headers.append('Content-Type', 'application/json');
    //     return this.httpClient.get(`${this.ottApiUrl}UserManageAccount/RefreshAccount/${Dishd2hSubscriptionID}/${this.getUserCategory()}`, { headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
    //         return res;
    //     });
    // }

    refreshAccount(token: string, Dishd2hSubscriptionID: number): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        let data: any = {
            SubscriptionID: Number(Dishd2hSubscriptionID),
            SubscriberCategory: Number(this.getUserCategory())
        };
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/RefreshAccount`, data, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    updateEmailId(token: string, reqObj: object): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        let body = reqObj;
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/UpdateEmailId`, body, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    updateMobileNumber(token: string, reqObj: object): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        let body = reqObj;
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/UpdateMobileNumber`, body, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    getComplaintTypeList(token: string, Dishd2hSubscriberID: string): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/GetComplaintTypeList`, { SubscriptionID: Number(Dishd2hSubscriberID), SubscriberCategory: Number(this.getUserCategory()) }, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    submitComplaint(token: string, reqObj: object): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        let body = reqObj;
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/SubmitFeedback`, body, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    getpackageDetails(token: string, Dishd2hSubscriberID: string): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/GetPackageDetails`, { SubscriptionID: Number(Dishd2hSubscriberID), SubscriberCategory: Number(this.getUserCategory()) }, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    // getencryptedUrl(token: string, Dishd2hSubscriberID: string): Observable<HttpResponse<any>> {
    //     let headers = new HttpHeaders().set('Authorization', token);
    //     headers = headers.append('Content-Type', 'application/json');
    //     return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/EncryptedUrl`, { SubscriptionID: Number(Dishd2hSubscriberID) }, { headers })
    //         .pipe((res: Observable<HttpResponse<any>>) => {
    //             return res
    //         });
    // }
    getencryptedUrl(token: string, Dishd2hSubscriberID: string, guid: string): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
    
        // Combine the parameters into a single payload object
        const payload = {
            SubscriptionID: Number(Dishd2hSubscriberID),
            guid: guid
        };
    
        return this.httpClient.post<HttpResponse<any>>(
            `${this.ottApiUrl}UniFiedAPP/EncryptedUrlWithUID`, 
            payload, 
            { headers }
        ).pipe(
            // No need to cast the response here, as it already returns an Observable<HttpResponse<any>>
            // You can handle response transformations or error handling here if needed
        );
    }
    

    getEpgToken() {
        if (this.isBrowser) {
            return JSON.parse(localStorage.getItem("mydishtv_token")).token;
        }
    }

    setEpgToken(res: object) {
        let obj = {
            token: res['token'],
            createdAt: new Date()
        }
        if (this.isBrowser) {
            localStorage.setItem("mydishtv_token", JSON.stringify(obj));
        }
    }

    getUserCategory() {
        if (this.isBrowser) {
            let usercategoryid = localStorage.getItem("user-category");
            console.log("***PP",usercategoryid)
            return usercategoryid
        }
    }

    getOTTSubscriberID() {
        if (this.isBrowser) {
            return JSON.parse(localStorage.getItem("user-sms")).OTTSMSID;
        }
    }

    setProgramDetail(programs: any) {
        if (this.isBrowser) {
            localStorage.setItem("programs", JSON.stringify(programs));
        }
    }

    getProgramDetail() {
        if (this.isBrowser) {
            return JSON.parse(localStorage.getItem("programs"));
        }
    }

    getOttApiToken() {
        if (this.isBrowser) {
            return localStorage.getItem("Authorization");
        }
    }

    getUserPlatform() {
        if (this.isBrowser) {
            return localStorage.getItem("user-category") == '1' ? 'dishtv' : 'd2h';
        }
    }

    IsEligibleForWhatsAppConsentFlag(token: string, Dishd2hSubscriberID: string, SubscriberCategory: string): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        let body: any = {
            OTTSubscriberID: Dishd2hSubscriberID,
            Flag: 0,
            SubscriberCategory: Number(SubscriberCategory),
            Source: 'Web'
        }
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/IsEligibleForWhatsAppConsentFlag`, body, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    // InsertWhatsAppConsentDetails(token: string, Dishd2hSubscriberID: string, SubscriberCategory: string): Observable<HttpResponse<any>> {
    //     let headers = new HttpHeaders().set('Authorization', token);
    //     headers = headers.append('Content-Type', 'application/json');
    //     return this.httpClient.get(`${this.ottApiUrl}UniFiedAPP/InsertWhatsAppConsentDetails?OTTSubscriberID=${Dishd2hSubscriberID}&Flag=0&SubscriberCategory=${SubscriberCategory}`, { headers })
    //         .pipe((res: Observable<HttpResponse<any>>) => {
    //             return res
    //         });
    // }
    InsertWhatsAppConsentDetails(token: string, Dishd2hSubscriberID: string, SubscriberCategory: string): Observable<HttpResponse<any>> {
        let body: any = {
            OTTSubscriberID: Dishd2hSubscriberID,
            SubscriberCategory: Number(SubscriberCategory),
            Source: 'Web'
        }
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/InsertWhatsAppConsentDetails`, body, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    IsEligibleForPayLater(token: string, Dishd2hSubscriberID: string, SubscriberCategory: string): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.get(`${this.ottApiUrl}UniFiedAPP/IsEligibleForPayLater?OTTSubscriberID=${Dishd2hSubscriberID}&SubscriberCategory=${SubscriberCategory}`, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    // InsertPayLater(token: string, Dishd2hSubscriberID: string, SubscriberCategory: string): Observable<HttpResponse<any>> {
    //     let headers = new HttpHeaders().set('Authorization', token);
    //     headers = headers.append('Content-Type', 'application/json');
    //     return this.httpClient.get(`${this.ottApiUrl}UniFiedAPP/SubmitPayLaterRequest?OTTSubscriberID=${Dishd2hSubscriberID}&SubscriberCategory=${SubscriberCategory}`, { headers })
    //         .pipe((res: Observable<HttpResponse<any>>) => {
    //             return res
    //         });
    // }
    InsertPayLater(token: string, Dishd2hSubscriberID: string, SubscriberCategory: string): Observable<HttpResponse<any>> {
        let body: any = {
            OTTSubscriberID: Number(Dishd2hSubscriberID),
            SubscriberCategory: Number(SubscriberCategory),
            Source: 'Web'
        }
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/SubmitPayLaterRequest`, body, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    SubmitUserRequestToGetOtherConnection(token: string, data): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/SubmitUserRequestToGetOtherConnection`, data, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    // upgradeBox(token: string, data): Observable<HttpResponse<any>> {
    //     let headers = new HttpHeaders().set('Authorization', token);
    //     headers = headers.append('Content-Type', 'application/json');
    //     return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/SubmitBoxUpgradeLead`, data, { headers })
    //         .pipe((res: Observable<HttpResponse<any>>) => {
    //             return res
    //         });
    // }
    upgradeBox(token: string, data): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/SubmitBoxUpgradeLeadRequest`, data, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }
    getEnID(token: string): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.get(`https://bfcafw1uea.execute-api.ap-south-1.amazonaws.com/prod/test`)
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }


    GenerateMobileOTP(token: string, data): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/GenerateOTP`, data, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }


    ValidateMobileOTP(token: string, data): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/ValidateOTP`, data, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }

    UpdateRMN(token: string, data): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/UpdateRMN`, data, { headers })
            .pipe((res: Observable<HttpResponse<any>>) => {
                return res
            });
    }
    // only for encrypted data show

    getUserAccountEncryptedDetailsdata(token: string, encryptparams: string): Observable<HttpResponse<any>> {
       
       let headers = new HttpHeaders().set('Content-Type', 'application/json');
         headers = headers.append('Authorization', token);
        console.log("***headers**", token);
       
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/GetSubscriberDetails`,encryptparams, { headers }).pipe((res: Observable<HttpResponse<any>>) => {
            console.log("**res**", res);
            return res;
        });
    }
    


    getComplaintIDStatus(token: string, complaintID: string): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('Authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        return this.httpClient.post(`${this.ottApiUrl}UniFiedAPP/ServiceTicketTracking`, { ComplaintNo: Number(complaintID), Source: 'APP' }, { headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });
    }

    EncryptUpdateRMN(token: string, OTTSubscriberID: string): Observable<HttpResponse<any>>{
        let headers = new HttpHeaders().set('Authorization', token);
        const params = new HttpParams().set('OTTSubscriberID',OTTSubscriberID)
        console.log("***tokens***", token);
        headers = headers.append('Content-Type', 'application/json');
        // const params={
        //     q:OTTSubscriberID
        // }
        return this.httpClient.get(`${this.ottApiUrl}api/v1/UpdateRMN`, {params:params,headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });

    }

    EncryptSubmitUserRequestToGetOtherConnection(token: string, OTTSubscriberID: string): Observable<HttpResponse<any>>{
        let headers = new HttpHeaders().set('Authorization', token);
        const params = new HttpParams().set('OTTSubscriberID',OTTSubscriberID)
        console.log("***tokens***", token);
        headers = headers.append('Content-Type', 'application/json');
        // const params={
        //     q:OTTSubscriberID
        // }
        return this.httpClient.get(`${this.ottApiUrl}api/v1/SubmitUserRequestToGetOtherConnection`, {params:params,headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });

    }

    getEncryptSubscriptiondetails(token: string, OTTSubscriberID: string): Observable<HttpResponse<any>>{
        let headers = new HttpHeaders().set('Authorization', token);
        const params = new HttpParams().set('OTTSubscriberID',OTTSubscriberID)
        console.log("***tokens***", token);
        headers = headers.append('Content-Type', 'application/json');
        // const params={
        //     q:OTTSubscriberID
        // }
        return this.httpClient.get(`${this.ottApiUrl}api/v1/getsubscriberEncrypt`, {params:params,headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });

    }
    EncryptupgradeBox(token: string, OTTSubscriberID: string): Observable<HttpResponse<any>>{
        let headers = new HttpHeaders().set('Authorization', token);
        const params = new HttpParams().set('OTTSubscriberID',OTTSubscriberID)
        console.log("***tokens***", token);
        headers = headers.append('Content-Type', 'application/json');
        // const params={
        //     q:OTTSubscriberID
        // }
        return this.httpClient.get(`${this.ottApiUrl}api/v1/upgradeBox`, {params:params,headers: headers }).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });

    }

    getEncryptSubscriptiondetailsTest(): Observable<HttpResponse<any>>{
        // let headers = new HttpHeaders().set('Authorization', token);
        // console.log("***tokens***", token);
        // headers = headers.append('Content-Type', 'application/json');
        // // const params={
        // //     q:OTTSubscriberID
        // // }
        return this.httpClient.get(`${this.ottApiUrl}api/v1/test`).pipe((res: Observable<HttpResponse<any>>) => {
            return res;
        });

    }

}