import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { KalturaAppService } from '../shared/services/kaltura-app.service';
import { AppFormService } from '../shared/services/shared-form.service';
import { AppConstants } from '../shared/typings/common-constants';
import { UUID } from 'angular2-uuid';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  isBrowser: any;

  hrs_1: number;
  dmsExpiryTime: number;
  ksExpiryTime: number;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private kalturaAppService: KalturaAppService, private appFormService: AppFormService) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.hrs_1 = 3600000;
    this.dmsExpiryTime = this.hrs_1 * 8;
    this.ksExpiryTime = this.hrs_1 * 24;
  }

  load(): Promise<any> {

    if (this.isBrowser) {

      // this.setExpiry();

      return this.handleDMSCall().then(res => {
        return this.handleKSCall().then(ksRes => {
          let udid;
          if (this.isBrowser) {
            if (!localStorage.getItem(AppConstants.UDID_KEY)) {
              udid = 'WEB-' + UUID.UUID();
              localStorage.setItem(AppConstants.UDID_KEY, udid);
            }
          }
        });
      });
    } else {
      return;
    }
  }

  handleDMSCall() {
    let callDms: boolean = false;
    if (localStorage.getItem(AppConstants.DMS_KEY) === undefined
      || localStorage.getItem(AppConstants.DMS_KEY) === null
      || localStorage.getItem(AppConstants.DMS_KEY).trim() === '') {
      callDms = true;
    } else {
      let expiryTime: any = sessionStorage.getItem(AppConstants.DMS_EXPIRY_KEY);
      if (expiryTime) {
        expiryTime = Number.parseInt(expiryTime);
        let currentTime = new Date().getTime();
        if ((currentTime - expiryTime) > this.dmsExpiryTime) {
          callDms = true;
        } else {
          callDms = false;
        }
      } else {
        this.setExpiry(true, false);
        callDms = true;
      }
    }
    if (callDms) {
      // return this.appFormService.getDms().toPromise().then(dms => {
        // this.setExpiry(true, false);
        // localStorage.setItem(AppConstants.DMS_KEY, JSON.stringify(dms.body));
        // return;
      // });
      if (environment.production) {
        localStorage.setItem(AppConstants.DMS_KEY, JSON.stringify(DMS_PROD));
      } else {
        localStorage.setItem(AppConstants.DMS_KEY, JSON.stringify(DMS_QA));
      }
      this.setExpiry(true, false);
      return Promise.resolve();
      // return;
    } else {
      return Promise.resolve();
    }
  }

  handleKSCall(): any {
    let callKs: boolean = false;
    if (localStorage.getItem(AppConstants.KS_KEY) === undefined
      || localStorage.getItem(AppConstants.KS_KEY) === null
      || localStorage.getItem(AppConstants.KS_KEY).trim() === '') {
      callKs = true;
    } else {
      if (localStorage.getItem(AppConstants.AUTH_HEADER_KEY) && localStorage.getItem(AppConstants.USER_DETAILS)) {
        callKs = false;
      } else {
        let expiryTime: any = localStorage.getItem(AppConstants.DMS_EXPIRY_KEY);
        if (expiryTime) {
          expiryTime = Number.parseInt(expiryTime);
          let currentTime = new Date().getTime();
          if ((currentTime - expiryTime) > this.ksExpiryTime) {
            callKs = true;
          } else {
            callKs = false;
          }
        } else {
          this.setExpiry(false, true);
          callKs = true;
        }
      }
    }
    if (callKs) {
      return this.kalturaAppService.makeAnonymusLogin().then(resolve => {
        this.setExpiry(false, true);
        return Promise.resolve();
      }, rej => {

      });
    } else {
      return Promise.resolve();
    }
  }

  setExpiry(setDMS?: boolean, setKS?: boolean) {
    if (!sessionStorage.getItem(AppConstants.DMS_EXPIRY_KEY) || setDMS) {
      sessionStorage.setItem(AppConstants.DMS_EXPIRY_KEY, new Date().getTime().toString());
    }
    if (!localStorage.getItem(AppConstants.KS_EXPIRY_KEY) || setKS) {
      localStorage.setItem(AppConstants.KS_EXPIRY_KEY, new Date().getTime().toString());
    }
  }
}

// DMS Config QA
export const DMS_QA = {
  "version": {
    "id": "c5d2bc0bc1c1bb3fad56004ddf6a389f",
    "is_default": true,
    "appname": "com.kaltura.dishtv.app",
    "clientversion": "0.0.1",
    "isforceupdate": false,
    "platform": 22,
    "partnerid": 487,
    "type": "configuration"
  },
  "params": {
    "FilesFormat": {
      "HLS": "HLS_Main",
      "DASH": "DASH_Main",
      "SS": "SS_Main"
    },
    "Gateways": {
      "JsonGW": "https://rest-sgs1.ott.kaltura.com"
    },
    "Categories": {
      "Root": "3194"
    },
    "updatedVersion": "1.1",
    "SMSURL": "https://dishd2hunifiedbck.watcho.com/",
    "MediaTypes": {
      "Movie": "556",
      "WebEpisode": "557",
      "TVEpisode": "595",
      "TVShowSeries": "594",
      "WebSeries": "559",
      "Linear": "558",
      "ShortFilm": "596",
      "CreatorVideo": "598",
      "UGCVideo": "597",
      "Trailer": "599",
      "UGCCreator": "600",
      "Genre": "601",
      "Clips": "602",
      "SpotlightEpisode": "603",
      "SpotlightSeries": "604",
      "UGCIFPImage": "620",
      "Program": "0"
    },
    "Genres": {
      "Vlog": "332451",
      "Science": "332452",
      "Sports": "332422",
      "Crime": "332421",
      "Chat_Shows": "332362",
      "Biopic": "332361",
      "Family": "332360",
      "Kids": "332355",
      "Documentary": "332344",
      "Reality": "332343",
      "Horror": "332340",
      "Action": "332339",
      "Thriller": "332313",
      "Romance": "332309",
      "Drama": "332307",
      "Lifestyle": "332395",
      "Comedy": "332394"
    },
    "ParentalRules": {
      "All": "21",
      "7+": "13",
      "13+": "18",
      "16+": "19",
      "18+": "20"
    },
    "ParentalDefaultRule": {
      "DefaultRule": "19"
    },
    "DummyParentalDefault": "22",
    "showContest": true
  },
  "extServices": {
    "kavaPartnerID": "2407831",
    "kalturaAPIVersion": "4.82.4.19258",
    "moengageAppID": "MRNQZ3CJR6ZPJWGHKUBI45U7",
    "moengageSenderID": "472482569908",
    "fbAppID": "505159006623180",
    "fbLoginProtocolScheme": "fb505159006623180",
    "branchKey": "key_live_ccQao1mM1VBbmplDExd0OplnuyckXC44",
    "identityPollID": "ap-southeast-1:d45fc715-4d24-4716-abc6-b48b53f15c60"
  },
  "staticURLs": {
    "termsAndConditions": "https://web-qa.watcho.com/web",
    "privacyPolicy": "https://web-qa.watcho.com/web",
    "video": "https://api-qa.watcho.com/",
    "ifpDetail": "http://5c063d30c16e120013947979.mockapi.io/",
    "playStore": "https://play.google.com/store/apps/details?id=com.watcho"
  },
  "baseChannels": {
    "home": "3207",
    "exclusive": "3209",
    "sunburn": "3210",
    "spotlight": "3208",
    "moreLiveTV": "3212",
    "moreTrending": "3213",
    "movieDetail": "3245",
    "shortFilmDetail": "3246",
    "webSeriesDetail": "3247",
    "webEpisodeDetail": "3248",
    "spotlightSeriesDetail": "3249",
    "spotlightEpisodeDetail": "3250",
    "liveTVDetail": "3251",
    "ugcCreatorProfileDetail": "3252",
    "ugcVideoDetail": "3253",
    "catchUpDetail": "3246",
    "forwardedEPGDetail": "3247",
    "staticSearch": "332229",
    "ifpChannel": "332568",
    "membershipPlans": "332618"
  }
}

// DMS Config PROD
export const DMS_PROD = {
  "version": {
    "id": "3edc2e06d42683c0e664c0c8e6c289b9",
    "is_default": true,
    "appname": "com.kaltura.dishtv.app",
    "clientversion": "1.0.1",
    "isforceupdate": false,
    "platform": 22,
    "partnerid": 487,
    "type": "configuration"
  },
  "params": {
    "FilesFormat": {
      "HLS": "HLS_Main",
      "DASH": "DASH_Main",
      "SS": "SS_Main"
    },
    "Gateways": {
      "JsonGW": "https://restv4-as.ott.kaltura.com"
    },
    "Categories": {
      "Root": "3204"
    },
    "updatedVersion": "1.1",
    "SMSURL": "https://unifiedbck.watcho.com/",
    "MediaTypes": {
      "Movie": "655",
      "WebEpisode": "656",
      "WebSeries": "658",
      "Linear": "657",
      "ShortFilm": "659",
      "UGCVideo": "660",
      "Trailer": "661",
      "UGCCreator": "662",
      "Genre": "663",
      "Clips": "664",
      "SpotlightEpisode": "665",
      "SpotlightSeries": "666",
      "UGCIFPImage": "667",
      "Program": "0"
    },
    "Genres": {
      "Vlog": "335108",
      "Science": "335109",
      "Sports": "335110",
      "Crime": "335111",
      "Chat_Shows": "335112",
      "Biopic": "335113",
      "Family": "335114",
      "Kids": "335115",
      "Documentary": "335116",
      "Reality": "335117",
      "Horror": "335118",
      "Action": "335119",
      "Thriller": "335120",
      "Romance": "335121",
      "Drama": "335122",
      "Lifestyle": "335123",
      "Comedy": "335124"
    },
    "ParentalRules": {
      "All": "17",
      "7+": "13",
      "13+": "14",
      "16+": "15",
      "18+": "16"
    },
    "ParentalDefaultRule": {
      "DefaultRule": "15"
    },
    "DummyParentalDefault": "18",
    "showContest": true
  },
  "extServices": {
    "kavaPartnerID": "2427592",
    "kalturaAPIVersion": "",
    "moengageAppID": "42LTIDYYXG4PF43PZZEA2NBI",
    "moengageSenderID": "115741393848",
    "fbAppID": "334649197274730",
    "fbLoginProtocolScheme": "fb334649197274730",
    "branchKey": "key_live_kfRjeIpRvXzvCEEzGMp6YmpaEFn7AXvK",
    "identityPollID": "ap-southeast-1:d45fc715-4d24-4716-abc6-b48b53f15c60"
  },
  "staticURLs": {
    "termsAndConditions": "https://www.watcho.com",
    "privacyPolicy": "https://web-qa.watcho.com/web",
    "video": "https://app-api.watcho.com/",
    "ifpDetail": "http://5c063d30c16e120013947979.mockapi.io/",
    "playStore": "https://play.google.com/store/apps/details?id=com.watcho"
  },
  "baseChannels": {
    "home": "3230",
    "exclusive": "3233",
    "sunburn": "0",
    "spotlight": "3234",
    "moreLiveTV": "3235",
    "moreTrending": "3236",
    "movieDetail": "3237",
    "shortFilmDetail": "3238",
    "webSeriesDetail": "3239",
    "webEpisodeDetail": "3240",
    "spotlightSeriesDetail": "3245",
    "spotlightEpisodeDetail": "3244",
    "liveTVDetail": "3241",
    "ugcCreatorProfileDetail": "3242",
    "ugcVideoDetail": "3243",
    "catchUpDetail": "3246",
    "forwardedEPGDetail": "3247",
    "staticSearch": "335127",
    "ifpChannel": "335610",
    "membershipPlans": "337241"
  }
}